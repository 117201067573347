export const InterSectionObserver = () => {
	const entries = document.querySelectorAll(".post");
	let options = {
		root: null,
		rootMargin: "0px",
		threshold: 0.5,
	};

	let observer = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry) => {
			const targetTerm = entry.target.dataset.term;
			const elems = document.querySelectorAll(".menu-item");
			const targetElem = Array.from(elems).filter((elem) => elem.innerText == targetTerm);
			if (entry.isIntersecting) {
				targetElem.forEach((elem) => (elem.style.fontWeight = "bold"));
			} else {
				targetElem.forEach((elem) => (elem.style.fontWeight = "normal"));
			}
		});
	}, options);

	entries.forEach((entry) => observer.observe(entry));
};
