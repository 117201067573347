import gsap from "gsap";

export const initAnimation = () => {
	const elems = document.querySelectorAll(".animate");

	const animateElement = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				gsap.to(entry.target, { duration: 0.6, opacity: 1, y: 0 });
				observer.unobserve(entry.target);
			}
		});
	};

	if (elems) {
		Array.from(elems).forEach((elem) => {
			gsap.set(elem, { y: 80, opacity: 0 });
			const observer = new IntersectionObserver(animateElement, {
				root: null,
				rootMargin: "0px",
				threshold: 0.2,
			});

			observer.observe(elem);
		});
	}
};
