// Must include the swiper slider first!
if (window.Swiper === undefined) {
	console.warn(
		"You must include the Swiper library (http://idangero.us/swiper/) in order for this controller to work."
	);
} else {
	var swiper = (function () {
		var swiperSliders = document.getElementsByClassName("swiper");
		for (i = 0; i < swiperSliders.length; i++) {
			new Swiper(swiperSliders[i], {
				autoHeight: false,
				speed: 2000,
				slidesPerView: 1,
				autoplay: {
					delay: 4000,
				},
				effect: "fade",
			});
		}
	})();
}
